<template>
    <div class="top">
        <div class="t-header">
            <div class="title">
                꿈, 걱정, 고민 무엇이든 멘토에게 물어보세요!<br>
                <span>아빠미소멘토단</span>
            </div>
        </div>
        <div class="filter-wrap clear">
            <div class="secret">
                <input type="checkbox" name="secret" id="secret" :value="true" :checked="filterSecret===1" @change="secretChange($event.target.checked)"></input>
                <label for="secret" >비밀글</label>
            </div>
            <button class="write" @click="moveWrite">글작성</button>
            <div class="search">
                <el-select class="select center" v-model="filterInputOption">
                    <el-option :value="'su'" :label="'제목'"></el-option>
                </el-select>
                <div class="input">
                    <input type="text" v-model="filterInput" @keypress.enter="setFilterFunc(filterInputOption, filterInput)"></input>
                    <button class="search_btn" @click="setFilterFunc(filterInputOption, filterInput)">검색</button>
                </div>
            </div>

        </div>
    </div>
</template>
<!--dd-->
<script>
    import {mapState, mapGetters} from "vuex";
    import EventBus from "@/utils/event-bus";
    import boardFilterUtil from "@/components/board/boardFilterUtil";
    const {filterConfig} = boardFilterUtil;

    export default {
        name: "BoardFilterCommuHomeLayout",
        inject: ['setPage', 'setData', 'setFilter'],
        props: {
            filterData: {
                default: () => {return {}}
            }
        },
        data() {
            return {
                filterType: '',
                filterInput: '',
                filterInputOption: 'su',
                filterSecret: '',
            }
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        mounted() {
            this.setterFilterDataInData();
        },
        methods: {
            setterFilterDataInData() {
                let classnames = Object.keys(this.filterData);
                classnames.forEach(classname => {
                    let obj = this.filterData[classname];
                    let type = filterConfig[obj.key].type;
                    if(this.hasOwnProperty(classname)) {
                        // this[classname] =
                        if(type == 'query') {
                            this[classname] = obj.query;
                        }else if(type === 'between') {
                            this[classname] = [obj.start, obj.end];
                        }else if(type === 'match') {
                            if(isNaN(obj.value)) {
                                this[classname] = obj.value;
                            }else{
                                this[classname] = Number(obj.value);
                            }

                        }

                    }
                })
            },
            parentSetData(key, value) {
                this[key] = value;
                this.setData(key, value);
            },
            setFilterFunc(key, value) {
                let classname = filterConfig[key].classname;
                if(!classname) {
                    return;
                }
                this[classname] = value;
                this.setData(classname, value);
                this.setFilter(key, value);
            },
            secretChange(checked) {
                console.log(checked)
                if(checked) {
                    this.setFilterFunc('isS', '1');
                }else{
                    this.setFilterFunc('isS', '');
                }
            },
            moveWrite() {
                this.$router.replace(this.$route.path);
                this.$nextTick(() => {
                    this.$router.push('commu/write');
                })
            },
        },
        watch: {},

    }
</script>

<style scoped>

</style>
