import { render, staticRenderFns } from "./BoardFilterCommuHomeLayout.vue?vue&type=template&id=f0a8775a&scoped=true&"
import script from "./BoardFilterCommuHomeLayout.vue?vue&type=script&lang=js&"
export * from "./BoardFilterCommuHomeLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0a8775a",
  null
  
)

export default component.exports